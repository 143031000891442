import { TrMetricsService } from "services/TrMetricsService";
import { isLoggedIn, getUserData } from "./utils/userService";
import { onDocumentReady } from "./utils/onDocumentReady";

const datavisor = window.raphael();
const datavisorAccessKey = window.tr_dv.access_key;
const datavisorEnvKey = window.tr_dv.env_key;

function initializeDatavisor() {
  // We want to delete the DVSession if the user logs out inside a browser
  // session/tab, so that a new DVToken can get created when logging in with
  // a potentially different account.
  if (!isLoggedIn() && isDVSessionSet()) {
    deleteDVSession();
  }

  if (isLoggedIn() && !isDVSessionSet()) {
    createDatavisorToken();
    sessionStorage.setItem("DVSession", "true");
  }
}

async function createDatavisorToken() {
  const userId = await getUserData("id");
  const flagKey = datavisor.setAccessKey(datavisorAccessKey);
  const envKey = datavisor.setEnv(datavisorEnvKey);
  const params = {};

  if (flagKey !== "success" && envKey !== "success") {
    return null;
  }

  if (userId) {
    params.userID = userId;
  }

  datavisor.initToken((token, error) => {
    if (!token || token === "empty") {
      fireDatavisorMetric(error);
    } else {
      fireDatavisorMetric("no error");
    }
  }, params);
}

function fireDatavisorMetric(error) {
  TrMetricsService.fire("datavisor_user_session_created", {
    datavisor_token: datavisor.getDVToken(),
    token_creation_error: error
  });
}

function isDVSessionSet() {
  return Boolean(sessionStorage.getItem("DVSession") !== null);
}

function deleteDVSession() {
  sessionStorage.removeItem("DVSession");
}

onDocumentReady(() => {
  initializeDatavisor();
});
